<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  onMounted,
  useRoute,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PageTrackingeGtm',
  props: {
    pageTitleGtm: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    pageTrackingEvent: {
      type: String,
      required: false,
      default() {
        return 'page_view';
      },
    },
    productIdTrackingContent: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
  },
  setup(props) {
    const { app } = useContext();
    const route = useRoute();
    const { fullPath } = route.value;
    onMounted(() => {
      const cookieConsent = app.$cookies.get('CookieConsent');
      if (app.$gtm) {
        app.$gtm.push([
          'consent',
          'default',
          {
            ad_personalization: 'denied',
            ad_storage: 'denied',
            ad_user_data: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'granted',
            wait_for_update: 500,
          },
        ]);
      }
      if (
        cookieConsent &&
        cookieConsent.includes('preferences:true') &&
        app.$gtm
      ) {
        if (props.productIdTrackingContent === '') {
          app.$gtm.push({
            event: props.pageTrackingEvent,
            content_name: props.pageTitleGtm,
            content_url: `${window.location.origin}${fullPath}`,
          });
        } else {
          app.$gtm.push({
            event: props.pageTrackingEvent,
            content_name: props.pageTitleGtm,
            content_url: `${window.location.origin}${fullPath}`,
            ID: props.productIdTrackingContent,
          });
        }
      }
    });
    return {
      app,
      route,
    };
  },
});
</script>

<style scoped></style>
