









import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VerticalLabel',
  props: {
    verticalLabel: {
      type: String,
      default: 'EXPLORE',
    },
  },
  setup() {
    const isVisible = ref<boolean>(false);
    const getIsVerticalLabelVisible = () => {
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const headerContainer = document.querySelector(
        '.sf-header-custom_container'
      );
      const headerContainerHeight =
        headerContainer?.getBoundingClientRect().height ?? 100;
      isVisible.value = currentScrollPosition > headerContainerHeight;
    };
    onMounted(() => {
      window.addEventListener('scroll', getIsVerticalLabelVisible, {
        passive: true,
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', getIsVerticalLabelVisible);
    });
    return {
      isVisible,
      getIsVerticalLabelVisible,
    };
  },
});
