<template>
  <div class="featured-product-slider__text">
    <div class="featured-product-slider__text__title">
      {{ title }}
    </div>
    <div class="featured-product-slider__text__subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FeaturedProductHeading',
  components: {},
  props: {
    title: {
      type: String,
      default: 'Featured Products',
    },
    subtitle: {
      type: String,
      default: 'Check out our latest featured products',
    },
  },
});
</script>
