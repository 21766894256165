var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasContentToShow)?_c('section',{staticClass:"journal-slider"},[_c('div',{staticClass:"journal-slider__text"},[_c('div',{staticClass:"journal-slider__text__title"},[_vm._v("\n      "+_vm._s(_vm.journalData.title || 'Divo Magazine')+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"journal-slider__text__button"},[_c('CustomButton',{attrs:{"layout":"white","link":_vm.fixUrlForCurrentLocale(
            ("/" + ((_vm.journalData && _vm.journalData.cta && _vm.journalData.cta.url) ||
              'magazine'))
          )}},[_vm._v("\n        "+_vm._s((_vm.journalData && _vm.journalData.cta && _vm.journalData.cta.label) ||
          'Magazine')+"\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"glide journal-slider__glide"},[(_vm.sanitizedSlides.length > 1)?_c('NextButton',{attrs:{"position":"both"},on:{"changeSlide":_vm.changeSlide}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"glide__track journal-slider__glide__track",attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides journal-slider__glide__track__slides"},_vm._l((_vm.sanitizedSlides),function(slide,index){return _c('li',{key:index,staticClass:"glide__slide journal-slider__glide__track__slides__slide"},[_c('JournalSlide',{class:("" + (index == _vm.previousSlideIndex
                ? 'prev'
                : index == _vm.nextSlideIndex
                ? 'next'
                : '') + (_vm.isDesktop && index == _vm.currentMountedSlideIndex
                ? ' slide-center'
                : '')),attrs:{"slide-data":slide,"slide-index":index},on:{"changeSlide":_vm.changeSlide}})],1)}),0)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }