<template>
  <JournalSlider
    :journal-data="journalSlides"
    v-if="
      journalSlides && journalSlides.slides && journalSlides.slides.length > 0
    "
  />
</template>
<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import useGeminiApi from '~/composables/useGeminiApi/useGeminiApi';
import { useI18n } from '~/helpers/hooks/usei18n';
import JournalSlider from '~/components/Homepage/JournalSlider/JournalSlider.vue';
export default defineComponent({
  name: 'MagazineSlider',
  components: { JournalSlider },
  setup() {
    const { findPayloadCollectionData } = useGeminiApi();
    const journalSlides = ref({ slides: [] });
    const {
      localeProperties: { localeValue },
    } = useI18n();

    useFetch(async () => {
      const { docs } = await findPayloadCollectionData('articles', localeValue);
      if (docs && docs.length > 0) {
        docs.forEach((doc) => {
          const imageData = (doc.image && doc.image[0]) ?? undefined;
          journalSlides.value.slides.push({
            active: true,
            ...(imageData && {
              image: {
                desktop:
                  (imageData.desktopImage && imageData.desktopImage.url) ?? '',
                mobile:
                  (imageData.mobileImage && imageData.mobileImage.url) ?? '',
                alt:
                  ((imageData.desktopImage && imageData.desktopImage.alt) ||
                    (imageData.mobileImage && imageData.mobileImage.alt)) ??
                  '',
              },
            }),
            link: doc.link ?? '',
            subtitle: doc.subtitle ?? '',
            text_color: doc.text_color ?? 'white',
            title: doc.title ?? '',
          });
        });
      }
    });
    return {
      journalSlides,
    };
  },
});
</script>
