<template>
  <div :class="`journal-slider__slide__content`">
    <div :class="`journal-slider__slide__content-text-container ${textColor}`">
      <span class="journal-slider__slide__content-text-container__subtitle">
        {{ subtitle }}
      </span>
      <br />
      <span class="journal-slider__slide__content-text-container__title">
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'JournalSliderContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {},
});
</script>

<style lang="scss">
.journal-slider__slide__content {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
  z-index: 1;
  &-text-container {
    text-transform: uppercase;
    color: var(--c-white);
    &.black {
      color: var(--c-black);
    }
    &__subtitle {
      @include paragraph-regular;
      letter-spacing: 0.05rem;
    }
    &__title {
      @include desktop-h4;
      letter-spacing: 0.8px;
      @include for-desktop {
        @include desktop-h3;
        letter-spacing: 0;
      }
    }
  }
}
</style>
