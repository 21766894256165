<template>
  <div class="featured-product-slider__slide">
    <nuxt-link :to="fixUrlForCurrentLocale(`/${slideData.url}`)">
      <nuxt-img
        class="featured-product-slider__slide__image"
        :src="`${slideData.thumbnail}?w=400&f=a`"
        format="webp"
        loading="lazy"
        :alt="`${slideData.productBrand + ' - ' + slideData.productName}`"
      />
      <div
        class="featured-product-slider__slide__brand display-desktop-on-hover"
      >
        {{ slideData.productBrand }}
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import { SfLink } from '@storefront-ui/vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'FeaturedProductSlide',
  components: { SfLink },
  props: {
    slideData: {
      type: Object,
      required: true,
    },
    slideIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { isDesktop } = useWindow();
    const { fixUrlForCurrentLocale } = useCurrentLocale();
    return {
      isDesktop,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.featured-product-slider__slide {
  position: relative;
  padding: 3.75rem 0;
  @include for-desktop {
    width: 100%;
    padding: 3.9375rem 0 4.6875rem;
  }
  height: 100%;
  @include desktop-boxed;
  overflow: hidden;
  .sf-link {
    --link-text-decoration: none;
  }
  &__image {
    width: 100%;
    height: 100%;
    max-height: 13.4375rem;
    object-fit: contain;
  }
  &:hover {
    .featured-product-slider__slide__image {
      transition: all ease-in-out 0.3s;
      transform: scale(1.2);
    }
    @include for-desktop {
      overflow: visible;
      .display-desktop-on-hover {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }
  &__brand {
    @include featured-product-brand;
    text-align: center;
    padding-top: 1.5rem;
  }
  @include for-desktop {
    .display-desktop-on-hover {
      opacity: 0;
    }
  }
}
</style>
