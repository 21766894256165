<template>
  <div class="journal-slider__slide" :class="`index-${slideIndex}`">
    <SfLink
      :link="fixUrlForCurrentLocale(`/${slideData.link}`)"
      target="_blank"
    >
      <nuxt-img
        class="journal-slider__slide__image"
        :src="slideImg || '/splash_screens/icon.png'"
        format="webp"
        loading="lazy"
        :alt="slideImgAlt"
      />
      <JournalSliderContent
        :title="slideData.title"
        :subtitle="slideData.subtitle"
        :text-color="slideData.text_color"
      />
    </SfLink>
  </div>
</template>

<script>
import { computed, defineComponent, inject } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import JournalSliderContent from './JournalSliderContent.vue';
import { SfLink } from '@storefront-ui/vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'JournalSlide',
  components: { JournalSliderContent, SfLink },
  props: {
    slideData: {
      type: Object,
      required: true,
    },
    slideIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const currentSlideIndex = inject('currentSlideIndex');
    const previousSlideIndex = inject('previousSlideIndex');
    const nextSlideIndex = inject('nextSlideIndex');

    const { isDesktop } = useWindow();

    const slideImg = computed(() =>
      isDesktop
        ? props?.slideData?.image?.desktop
        : props?.slideData?.image?.mobile
    );
    const slideImgAlt = computed(
      () => props?.slideData?.image?.alt || `journal${props.slideIndex}`
    );

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      isDesktop,
      currentSlideIndex,
      previousSlideIndex,
      nextSlideIndex,
      slideImg,
      slideImgAlt,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.journal-slider__slide {
  // width: 100vw;
  position: relative;
  @include for-desktop {
    width: 100%;
  }
  height: 100%;
  @include desktop-boxed;
  overflow: hidden;
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
