import { render, staticRenderFns } from "./NextButton.vue?vue&type=template&id=5d9cbdbe&scoped=true"
import script from "./NextButton.vue?vue&type=script&lang=js"
export * from "./NextButton.vue?vue&type=script&lang=js"
import style0 from "./NextButton.vue?vue&type=style&index=0&id=5d9cbdbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d9cbdbe",
  null
  
)

export default component.exports