export type PageOptionValue = {
  it?: string;
  en?: string;
  es?: string;
  de?: string;
  fr?: string;
};
export type PageOptionsType = Array<PageOptionValue>;
const pagesOptions = [
  {
    it: 'contatti',
    en: 'contatti',
  },
  {
    it: 'boutiques',
    en: 'boutiques',
  },
  {
    it: 'contacts',
    en: 'contacts',
  },
  {
    it: 'work-with-us',
    en: 'work-with-us',
  },
  {
    it: 'workwithus',
    en: 'workwithus',
  },
  {
    it: 'newsletter',
    en: 'newsletter',
  },
  {
    it: 'ourbrands',
    en: 'ourbrands',
  },
  {
    it: 'resi-e-cambio-merce',
    en: 'returns-and-items-change',
  },
  {
    it: 'pagamenti-e-rimborsi',
    en: 'payments-and-refunds',
  },
  {
    it: 'spedizioni',
    en: 'shipments',
  },
  {
    it: 'faq',
    en: 'faq',
  },
  {
    it: 'contact',
    en: 'contact',
  },
] as PageOptionsType;

export default pagesOptions;
