











import { defineComponent } from '@vue/composition-api';
import { useVisitor } from '~/composables';
import criteoHelper from '~/helpers/criteo';
import { useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HomepageTracking',
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
    },
  },
  setup() {
    const isClient = typeof window !== 'undefined'; // Ensure this runs only on the client side
    const { app } = useContext();
    return {
      isClient,
      app,
    };
  },
  mounted() {
    const { visitorId } = useVisitor();
    const criteo = criteoHelper({
      app: this.app,
      user: this.user,
      isAuthenticated: this.isAuthenticated,
      visitorId,
    });
    const deviceType = criteo.getDeviceType();
    const data: any = {
      visitorId: visitorId.value, // Optional: visitor ID, if available
      deviceType: deviceType,
    };

    if (this.isAuthenticated) {
      const email = criteo.encryptValue(this.user.email);
      data.email = email;
      data.hashMethod = 'MD5';
      data.customerId = email;
    }

    this.$criteo.homepageTag(data);
  },
});
