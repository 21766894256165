export type HreflangElement = {
  locale: string;
  lang: string;
  defaultCountry: string;
};

export const hreflangMapping: HreflangElement[] = [
  { locale: 'it-it', lang: 'it', defaultCountry: 'IT' },
  { locale: 'it-en', lang: 'en', defaultCountry: 'IT' },
  { locale: 'sg-en', lang: 'en', defaultCountry: 'SG' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'NO' },
  { locale: 'jp-en', lang: 'en', defaultCountry: 'JP' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NZ' },
  { locale: 'gb-it', lang: 'it', defaultCountry: 'GB' },
  { locale: 'gb-en', lang: 'en', defaultCountry: 'GB' },
  { locale: 'hk-en', lang: 'en', defaultCountry: 'HK' },
  { locale: 'kw-en', lang: 'en', defaultCountry: 'KW' },
  { locale: 'us-it', lang: 'it', defaultCountry: 'US' },
  { locale: 'us-en', lang: 'en', defaultCountry: 'US' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'HR' },
  { locale: 'au-it', lang: 'it', defaultCountry: 'AU' },
  { locale: 'au-en', lang: 'en', defaultCountry: 'AU' },
  { locale: 'mo-en', lang: 'en', defaultCountry: 'MO' },
  { locale: 'ch-it', lang: 'it', defaultCountry: 'CH' },
  { locale: 'ch-en', lang: 'en', defaultCountry: 'CH' },
  { locale: 'qa-en', lang: 'en', defaultCountry: 'QA' },
  { locale: 'bh-en', lang: 'en', defaultCountry: 'BH' },
  { locale: 'ca-it', lang: 'it', defaultCountry: 'CA' },
  { locale: 'ca-en', lang: 'en', defaultCountry: 'CA' },
  { locale: 'ae-it', lang: 'it', defaultCountry: 'AE' },
  { locale: 'ae-en', lang: 'en', defaultCountry: 'AE' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'FR' },
  { locale: 'kr-en', lang: 'en', defaultCountry: 'KR' },
  { locale: 'cn-en', lang: 'en', defaultCountry: 'CN' },
  { locale: 'de-en', lang: 'en', defaultCountry: 'DE' },
  { locale: 'de-it', lang: 'it', defaultCountry: 'DE' },
];
