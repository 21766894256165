import { ref, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import { isClient } from '~/helpers/check-environment';
import { getListUrlRewritesByTargetPaths } from '~/serverMiddleware/getGeminiData';

const useGeminiApi = () => {
  const geminiData = ref() as unknown as Record<string, unknown>;
  const loading = ref(false);
  const error = ref() as unknown as Record<string, unknown>;
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
      i18n: {
        localeProperties: { code: localeCode, localeValue },
      },
    },
  } = useContext();

  const getPayloadData = async (collectionName: string, id: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.GetPayloadData,
        {
          collectionName,
          id,
          locale: localeValue || localeCode,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (getPayloadDataError) {
      console.error('getPayloadData ~ error:', getPayloadDataError);
    }
    return null;
  };

  const findPayloadData = async (collectionName: string, query: Record<string, unknown>, locale: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.FindPayloadData,
        {
          collectionName,
          query,
          locale,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (findPayloadDataError) {
      console.error('findPayloadData ~ error:', findPayloadDataError);
    }
    return null;
  };

  const findPayloadCollectionData = async (collectionName: string, locale: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.FindPayloadCollectionData,
        {
          collectionName,
          locale,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        }
      );
      if (status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
      }
    } catch (findPayloadCollectionDataError) {
      console.error('findPayloadData ~ error:', findPayloadCollectionDataError);
    }
    return null;
  };

  const getGeminiData = async (entityGrn: string) => {
    loading.value = true;
    geminiData.value = null;
    error.value = null;
    try {
      if (process.client) {
        const response = await axios.get(`/api/getgeminidata`, {
          params: {
            grn: entityGrn,
            time: Date.now(),
          },
        });
        geminiData.value = response.data;
      } else {
        geminiData.value = await getListUrlRewritesByTargetPaths(entityGrn);
      }
    } catch (error_) {
      error.value = error_;
    }
    loading.value = false;
  };

  return {
    geminiData,
    loading,
    error,
    getGeminiData,
    getPayloadData,
    findPayloadData,
    findPayloadCollectionData,
  };
};

export default useGeminiApi;
