<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PageGtm',
  setup() {
    const { app } = useContext();
    onMounted(() => {
      const cookieConsent = app.$cookies.get('CookieConsent');
      if (
        cookieConsent &&
        cookieConsent.includes('preferences:true') &&
        app.$gtm
      ) {
        app.$gtm.push({
          event: 'cms_page',
          currencyCode: 'EUR',
          PageType: 'cms-page',
        });
      }
    });
    return {
      app,
    };
  },
});
</script>

<style scoped></style>
