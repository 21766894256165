<template>
  <div v-show="false" />
</template>

<script>
import { defineComponent, useMeta } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Organization',
  head: {},
  setup() {
    const structuredOrganizationData = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: `https://www.divoboutique.com/`,
      logo: `https://www.divoboutique.com/icons/logo.svg`,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+39 0587 707130',
          email: 'customercare@divoboutique.com',
          contactType: 'customer service',
        },
      ],
    };
    useMeta(() => ({
      script: [
        {
          innerHTML: JSON.stringify(structuredOrganizationData),
          type: 'application/ld+json',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }));
  },
});
</script>
