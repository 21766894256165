<template>
  <div v-show="false" />
</template>

<script>
import { defineComponent, useMeta, computed } from '@nuxtjs/composition-api';
import { handleSeo } from '~/helpers/seo/handleSeo';

export default defineComponent({
  name: 'Website',
  head: {},
  setup() {
    const { getCanonical } = handleSeo();
    const canonicalLink = computed(() => getCanonical());

    const websiteData = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: `${canonicalLink.value?.href || 'https://www.divoboutique.com'}/`,
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${
            canonicalLink.value?.href || 'https://www.divoboutique.com'
          }/search?term={search_term_string}`,
        },
        'query-input': 'required name=search_term_string',
      },
    };
    useMeta(() => ({
      script: [
        {
          innerHTML: JSON.stringify(websiteData),
          type: 'application/ld+json',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }));
  },
});
</script>
