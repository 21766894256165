/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useRoute } from '@nuxtjs/composition-api';
import pagesOptions from '~/helpers/alternate/cmsMapping';
import { HreflangElement, hreflangMapping } from '~/helpers/alternate/hreflangMapping';

export const useGetAlternateMatching = () => {
  const {
    app: {
      $vsf: {
        $gemini: {
          // @ts-ignore
          config: { geminiBaseUrl },
        },
      },
    },
  } = useContext();
  const route = useRoute();
  const alternateArray = [];

  const cmsPagesUrlMapping = pagesOptions;

  const buildHreflang = (mappingElement: HreflangElement) => {
    return `${mappingElement.lang}-${mappingElement.defaultCountry.toUpperCase()}` as string;
  };
  const prepareAlternateData = (mapping?: HreflangElement, url?: string) => {
    const hreflang = buildHreflang(mapping);

    const elementToAdd = {
      rel: 'alternate',
      hreflang,
      href: `${geminiBaseUrl || 'https://www.divoboutique.com'}/${mapping.locale}/${url ?? ''}`.replace(/\/$/, ''),
    };
    if (mapping.locale === 'row-en') {
      const xDefaultElementToAdd = {
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${geminiBaseUrl || 'https://www.divoboutique.com'}/row-en/${url ?? ''}`.replace(/\/$/, ''),
      };
      // @ts-ignore
      alternateArray.push(xDefaultElementToAdd);
    } else {
      // @ts-ignore
      alternateArray.push(elementToAdd);
    }
  };

  const getCmsUrl = (cmsMappingElementFound: any, langCode: string) => {
    let url = cmsMappingElementFound.en as string;
    if (langCode === 'it') url = cmsMappingElementFound.it;
    return url;
  };

  const createPageAlternateArray = (urlPath: string) => {
    const pageData = cmsPagesUrlMapping.filter((element) => Object.values(element).includes(urlPath ?? ''));
    if (pageData.length === 0 && route.value.name?.toLowerCase().includes('homepage')) {
      hreflangMapping.forEach((mapping) => prepareAlternateData(mapping, ''));
    }
    if (pageData.length > 0) {
      hreflangMapping.forEach((mapping) => prepareAlternateData(mapping, getCmsUrl(pageData[0], mapping.lang)));
    }
    return (alternateArray || []) as [];
  };
  const createAlternateArray = (geminiData: any) => {
    if (geminiData) {
      hreflangMapping.forEach((mapping) => {
        const dataMatchingLang = geminiData.filter((element) => element.context.includes(mapping.lang));
        if (dataMatchingLang) {
          prepareAlternateData(mapping, dataMatchingLang[0].requestPath as string);
        }
      });
    }

    return (alternateArray || []) as [];
  };

  const getAlternateArray = (geminiData: any) => {
    return createAlternateArray(geminiData);
  };

  const getAlternatePageArray = () => {
    const path = route.value?.params?.path || route.value?.path.split('/')[2];
    return createPageAlternateArray(path);
  };

  return {
    createPageAlternateArray,
    cmsPagesUrlMapping,
    getAlternateArray,
    createAlternateArray,
    getAlternatePageArray,
  };
};
