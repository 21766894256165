<template>
  <div class="featured-product-slider">
    <div class="swiper-container">
      <swiper ref="mainSwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(slide, index) in productsData"
          :key="`featured-products-slide-${index}-${slide.url}`"
        >
          <FeaturedProductSlide :slide-data="slide" :slide-index="index" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev">
        <img src="@/assets/images/leftArrow.png" alt="" loading="lazy" />
      </div>
      <div class="swiper-button-next">
        <img src="@/assets/images/rightArrow.png" alt="" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import NextButton from './NextButton.vue';
import FeaturedProductSlide from './FeaturedProductSlide.vue';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
SwiperCore.use([Autoplay, Navigation]);
import 'swiper/swiper.scss';
export default defineComponent({
  name: 'FeaturedProductGlide',
  components: {
    NextButton,
    FeaturedProductSlide,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    sliderId: {
      type: String,
      default: 'featured-product',
    },
    productsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        observer: true,
        observeParents: true,
        resizeObserver: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 16,
            centeredSlides: true,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 36,
          },
          1200: {
            slidesPerView: 7,
            spaceBetween: 36,
          },
        },
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.featured-product-slider {
  position: relative;
  z-index: 0;
  .swiper-button-prev,
  .swiper-button-next {
    margin: auto 3.125rem;
    width: 30px;
    height: 64px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    @include for-mobile {
      margin: auto 0.625rem;
      width: 15px;
      height: 35px;
    }
  }
  .swiper-button-next {
    left: auto;
    right: 0;
  }
}
</style>
