<template>
  <nuxt-link
    class="genre-link"
    :to="fixUrlForCurrentLocale(`${data.url}`)"
    :target="`${data.target}`"
  >
    <nuxt-img
      :src="data.src || '/splash_screens/icon.png'"
      :alt="data.alt"
      class="genre-image"
      :class="{ hovered }"
      @load="$emit('load')"
      loading="lazy"
    />
    <div class="block-text">
      {{ data.title }}
      <div class="hover-text" v-if="!!hovered">
        {{ data.label }}
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'GenresBlockLink',
  components: { SfLink },
  props: {
    data: {
      type: Object,
      required: true,
    },
    hovered: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { fixUrlForCurrentLocale } = useCurrentLocale();
    return {
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.genre-link {
  display: flex;
  height: 100%;
  .genre-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .block-text {
    text-align: center;
  }
}
</style>
