<template>
  <div class="journal-slider__next">
    <div
      v-if="position === 'both' || position === 'left'"
      class="journal-slider__next__button journal-slider__next__button__left"
      @click="$emit('changeSlide', '<')"
    >
      <nuxt-img
        class="prev"
        src="/icons/general/leftArrow.png"
        format="webp"
        :width="isMobileOrTablet ? '15' : '30'"
        :height="isMobileOrTablet ? '30' : '60'"
      />
    </div>
    <div
      v-if="position === 'both' || position === 'right'"
      class="journal-slider__next__button journal-slider__next__button__right"
      @click="$emit('changeSlide', '>')"
    >
      <nuxt-img
        class="next"
        src="/icons/general/rightArrow.png"
        format="webp"
        :width="isMobileOrTablet ? '15' : '30'"
        :height="isMobileOrTablet ? '30' : '60'"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';

export default defineComponent({
  name: 'NextButton',
  components: {},
  props: {
    position: {
      type: String,
      default: 'both',
      validator: (value) => ['right', 'left', 'both'].includes(value),
    },
  },
  setup() {
    const { isMobileOrTablet } = useWindow();
    return {
      isMobileOrTablet,
    };
  },
});
</script>

<style lang="scss" scoped>
.journal-slider__next {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__button {
    color: var(--c-white);
    &__left {
      margin-left: 0.625rem;
      @include for-desktop {
        margin-left: 3.125rem;
      }
    }
    &__right {
      margin-right: 0.625rem;
      @include for-desktop {
        margin-right: 3.125rem;
      }
    }
  }
}
</style>
