import { useRoute, useContext } from '@nuxtjs/composition-api';

export const handleSeo = () => {
  const route = useRoute();
  const { app } = useContext();
  const { keys } = Object;
  const baseUrl = 'https://www.divoboutique.com';

  const getCanonical = (): object => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const origin = app?.$vsf?.$gemini?.config?.geminiBaseUrl ?? baseUrl;
    const canonicalPath = route.value?.query?.page ? `${route.value?.path}${'?page='}${route.value?.query?.page}` : `${route.value?.path}`;
    const metaCanonical = {
      hid: 'canonical',
      rel: 'canonical',
      href: `${origin ?? baseUrl}${canonicalPath}`,
    };
    if (route.value?.query && keys(route.value.query).includes('brand') && !route.value.path.includes('designer')) {
      const brandFilter = typeof route.value?.query.brand === 'object' ? route.value?.query.brand[0] : route.value?.query.brand;
      metaCanonical.href = `${origin ?? baseUrl}${route.value?.path}?brand=${brandFilter.replace(':', '%3A')}`;
    }
    return metaCanonical;
  };

  return {
    getCanonical,
  };
};
